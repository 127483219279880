import { template as template_10099ff16e02455fbc3e0a1ad7794b35 } from "@ember/template-compiler";
const FKControlMenuContainer = template_10099ff16e02455fbc3e0a1ad7794b35(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
