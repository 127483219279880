import { template as template_f278f7477a7f41989f59b84486c8f841 } from "@ember/template-compiler";
const FKLabel = template_f278f7477a7f41989f59b84486c8f841(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
