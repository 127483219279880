import { template as template_e323b2559c644fdba0d6109412d6e8fb } from "@ember/template-compiler";
const WelcomeHeader = template_e323b2559c644fdba0d6109412d6e8fb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
